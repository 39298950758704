import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../containers/layout'
import PersonalBlog from '../containers/home'
import SEO from '../components/seo'

const HomePage = (props: any) => {
    const { data } = props

    return (
        <Layout>
            <SEO
                title="Cẩm nang làm đẹp"
                description={data.site.siteMetadata.description}
            />
            <PersonalBlog />
        </Layout>
    )
}

export default HomePage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`
